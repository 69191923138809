import * as React from "react"
import { Link } from "gatsby"
import { ManaLogo, WhiteNet } from "../components/logos"

const Footer = ({ siteTitle }) => (
  <footer>
    <ul className="nav-menu">
      <li className="nav-menu__item">
        <Link to="/" className="relative logo">
          <ManaLogo width="auto" height="100%" />
          <div className="liquid"></div>
        </Link>
      </li>
      <li className="nav-menu__item">
        <a href="mailto:nico@wearemana.se">nico@wearemana.se</a>
        <div className="liquid"></div>
      </li>
      <li className="nav-menu__item">
        <a href="tel:+46723652399">+46 72 365 23 99</a>
        <div className="liquid"></div>
      </li>
      <li className="nav-menu__item">
        <span>
          St Eriksgatan 17 <br /> 112 39 Stockholm
        </span>
        <div className="liquid"></div>
      </li>
    </ul>
    <span>© 2021 MANA. ALL RIGHTS RESERVED</span>
  </footer>
)

export default Footer
