import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { ManaLogo } from "../components/logos"

const Header = ({ siteTitle }) => (
  <header>
    <ul className="nav-menu">
      <li className="nav-menu__item">
        <Link to="/" className="relative logo">
          <ManaLogo width="auto" height="100%" />
          <div className="liquid"></div>
        </Link>
      </li>
      <li className="nav-menu__item">
        <a href="mailto:nico@wearemana.se">nico@wearemana.se</a>
        <div className="liquid"></div>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ManaLogo,
}

export default Header
