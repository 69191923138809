import React from "react"

const ManaLogo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 60" {...props}>
      <path d="M39.2 33.2c0-8.3 4.9-12.5 12.2-12.5 7.3 0 12.2 4.2 12.2 12.5V46h-7.5V33.2c0-3.9-1.9-5.5-4.7-5.5s-4.7 1.6-4.7 5.5V46h-7.5V33.2zM66.4 33.2c0-8.3 4.9-12.5 12.2-12.5 7.3 0 12.2 4.2 12.2 12.5V46h-7.5V33.2c0-3.9-1.9-5.5-4.7-5.5s-4.7 1.6-4.7 5.5V46h-7.5V33.2zM93.6 33.2c0-8.3 4.9-12.5 12.2-12.5S118 24.9 118 33.2V46h-7.5V33.2c0-3.9-1.9-5.5-4.7-5.5s-4.7 1.6-4.7 5.5V46h-7.5V33.2zM0 33.2C0 25 4.4 20.7 11 20.7s11 4.2 11 12.5V46h-6.7V33.2c0-3.9-1.7-5.5-4.2-5.5s-4.2 1.6-4.2 5.5V46H0V33.2zM19.7 21.9c1.6-.8 3.5-1.2 5.3-1.2 6.6 0 11 4.2 11 12.5V46h-6.7V33.2c0-3.9-1.7-5.5-4.2-5.5-.5 0-.9.1-1.4.2-.6-1.1-2.6-5.1-4-6z" />
      <g>
        <path d="M51.159 42h-11.9v-6.7h11.9V42z" />
      </g>
      <g>
        <path d="M105.5 42H93.6v-6.7h11.9V42z" />
      </g>
    </svg>
  )
}

const AccompliceLogo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195 60" {...props}>
      <g
        id="Group_547"
        data-name="Group 547"
        transform="translate(-88.555 223.918)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M103.993-185.2a7.027,7.027,0,0,1-5.265,1.935c-5.178,0-10.173-5-10.173-11.163s4.995-11.163,10.173-11.163a6.879,6.879,0,0,1,5.265,1.979v-1.35h5.763v21.065h-5.763Zm0-12.515a5.308,5.308,0,0,0-4.275-2.114,5.447,5.447,0,0,0-5.4,5.4,5.447,5.447,0,0,0,5.4,5.4,5.31,5.31,0,0,0,4.275-2.116Z"
          transform="translate(0 4.808)"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M100.375-194.424a11.159,11.159,0,0,1,11.163-11.163v5.761a5.418,5.418,0,0,0-5.4,5.4,5.418,5.418,0,0,0,5.4,5.4v5.761A11.16,11.16,0,0,1,100.375-194.424Z"
          transform="translate(13.122 4.808)"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M106.9-194.424a11.159,11.159,0,0,1,11.163-11.163v5.761a5.418,5.418,0,0,0-5.4,5.4,5.418,5.418,0,0,0,5.4,5.4v5.761A11.16,11.16,0,0,1,106.9-194.424Z"
          transform="translate(20.36 4.808)"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M113.43-194.424a11.159,11.159,0,0,1,11.163-11.163,11.16,11.16,0,0,1,11.163,11.163,11.161,11.161,0,0,1-11.163,11.163A11.16,11.16,0,0,1,113.43-194.424Zm11.163,5.4a5.418,5.418,0,0,0,5.4-5.4,5.418,5.418,0,0,0-5.4-5.4,5.418,5.418,0,0,0-5.4,5.4A5.418,5.418,0,0,0,124.593-189.023Z"
          transform="translate(27.614 4.808)"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M133.98-199.826a2.78,2.78,0,0,0-2.475,1.621v14.313h-5.763v-21.065H131.5v1.665a6.1,6.1,0,0,1,4.592-2.294,5.664,5.664,0,0,1,4.727,2.384,8.261,8.261,0,0,1,5.581-2.384c3.826,0,6.436,2.61,6.436,7.155v14.539H147.08v-13.458a2.239,2.239,0,0,0-2.431-2.475,2.736,2.736,0,0,0-2.477,1.621v14.313h-5.761v-13.458A2.238,2.238,0,0,0,133.98-199.826Z"
          transform="translate(41.282 4.808)"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M146.563-203.608a6.891,6.891,0,0,1,5.267-1.979c5.178,0,10.173,4.995,10.173,11.163s-4.995,11.163-10.173,11.163a7.039,7.039,0,0,1-5.267-1.935v9.633H140.8v-29.394h5.761Zm0,5.9v6.573a5.317,5.317,0,0,0,4.277,2.116,5.448,5.448,0,0,0,5.4-5.4,5.448,5.448,0,0,0-5.4-5.4A5.315,5.315,0,0,0,146.563-197.712Z"
          transform="translate(58.001 4.808)"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M152.6-179.085v-30.833h5.761v30.833Z"
          transform="translate(71.101 0)"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M157.527-203.217v-5.763h5.763v5.763Zm0,23.091v-21.065h5.763v21.065Z"
          transform="translate(76.568 1.041)"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M162-194.424a11.16,11.16,0,0,1,11.163-11.163v5.761a5.416,5.416,0,0,0-5.4,5.4,5.416,5.416,0,0,0,5.4,5.4v5.761A11.161,11.161,0,0,1,162-194.424Z"
          transform="translate(81.531 4.808)"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M187.573-186.5a11.087,11.087,0,0,1-7.877,3.241,11.161,11.161,0,0,1-11.163-11.163A11.16,11.16,0,0,1,179.7-205.587a11.16,11.16,0,0,1,11.163,11.163,12.162,12.162,0,0,1-.179,2.205H174.745a5.419,5.419,0,0,0,4.95,3.2,5.2,5.2,0,0,0,3.781-1.576Zm-12.827-10.129H184.6a5.408,5.408,0,0,0-4.906-3.195A5.421,5.421,0,0,0,174.745-196.632Z"
          transform="translate(88.786 4.808)"
        />
      </g>
    </svg>
  )
}

const WhiteNet = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="45 0 50 50">
      <g
        data-name="Group 579"
        // opacity={0.701}
        style={{
          isolation: "isolate",
        }}
        fill="none"
        stroke="#e2000"
        strokeWidth={3}
        width="200px"
      >
        <path data-name="Line 34" d="M495.096 160.813L92.845 393.053" />
        <path data-name="Line 26" d="M544.225 245.907l-402.251 232.24" />
        <path data-name="Line 42" d="M445.966 75.718l-402.25 232.24" />
        <path data-name="Line 22" d="M568.701 288.302l-402.25 232.24" />
        <path data-name="Line 38" d="M470.619 118.419L68.369 350.658" />
        <path data-name="Line 30" d="M519.572 203.208l-402.25 232.24" />
        <path data-name="Line 46" d="M421.49 33.324L19.239 265.564" />
        <path data-name="Line 35" d="M482.769 139.463l-402.25 232.24" />
        <path data-name="Line 27" d="M531.899 224.558l-402.251 232.24" />
        <path data-name="Line 44" d="M433.816 54.674l-402.25 232.24" />
        <path data-name="Line 23" d="M556.375 266.952l-402.25 232.24" />
        <path data-name="Line 40" d="M458.293 97.068L56.042 329.308" />
        <path data-name="Line 31" d="M507.422 182.162L105.17 414.402" />
        <path data-name="Line 36" d="M488.933 150.138L86.682 382.378" />
        <path data-name="Line 28" d="M538.062 235.232L135.81 467.472" />
        <path data-name="Line 43" d="M439.979 65.349L37.729 297.588" />
        <path data-name="Line 24" d="M562.538 277.627l-402.25 232.24" />
        <path data-name="Line 39" d="M464.456 107.743L62.205 339.983" />
        <path data-name="Line 32" d="M513.585 192.838l-402.25 232.24" />
        <path data-name="Line 47" d="M415.327 22.65L13.076 254.888" />
        <path data-name="Line 37" d="M476.782 129.093l-402.25 232.24" />
        <path data-name="Line 29" d="M525.735 213.882l-402.25 232.24" />
        <path data-name="Line 45" d="M427.653 44L25.403 276.238" />
        <path data-name="Line 25" d="M550.388 256.583l-402.25 232.239" />
        <path data-name="Line 41" d="M452.13 86.394L49.879 318.634" />
        <path data-name="Line 33" d="M501.259 171.488L99.008 403.728" />
        <path data-name="Line 18" d="M593.178 330.696L190.774 563.024" />
        <path data-name="Line 20" d="M581.028 309.652l-402.251 232.24" />
        <path data-name="Line 48" d="M409.163 11.974L6.913 244.214" />
        <path data-name="Line 19" d="M587.19 320.326l-402.25 232.24" />
        <path data-name="Line 21" d="M574.865 298.977l-402.251 232.24" />
        <path data-name="Line 49" d="M403 1.299L.75 233.539" />
      </g>
    </svg>
  )
}

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 476.213 476.213"
      version="1.1"
      viewBox="0 0 476.213 476.213"
      xmlSpace="preserve"
    >
      <path d="M345.606 107.5L324.394 128.713 418.787 223.107 0 223.107 0 253.107 418.787 253.107 324.394 347.5 345.606 368.713 476.213 238.106z"></path>
    </svg>
  )
}

export { ManaLogo, AccompliceLogo, WhiteNet, Arrow }
